import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { getLocalCookies, getServerCookies, ICookie } from '../api/cookies';
import { getUser, User } from '../api/user';
import { Error as ErrorComp } from '../components/shared';

export const Home = () => {

    // Check local cookies
    const [localCookies, setLocalCookies] = useState<ICookie[]>([]);
    useEffect(() => {
        getLocalCookies().then((cookies) => setLocalCookies(cookies));
    }, []);

    // Fetch cookies from server
    const [serverCookies, setServerCookies] = useState<ICookie[]>([]);
    const [serverError, setServerError] = useState<Error|null>(null);
    useEffect(() => {
        getServerCookies()
            .then((cookies) => {
                setServerCookies(cookies);
            })
            .catch((error) => {
                setServerError(error);
            });
    }, []);

    // Fetch user from S2
    const [s2User, setS2User] = useState<User|null>(null);
    const [s2Error, setS2Error] = useState<Error|null>(null);
    useEffect(() => {
        getUser()
            .then((resp) => {
                setS2User(resp.user);
            })
            .catch((error) => {
                setS2Error(error);
            });
    }, []);

    console.log({
        localCookies,
        serverCookies,
        serverError,
        s2User,
        s2Error,
    });

    return (
        <React.Fragment>
            <TwoColumnGrid>
                <div>
                    <h3>Local Cookies</h3>
                    <CookieValues>
                        {localCookies.map(({ name, value }) => (
                            <React.Fragment key={name}>
                                <CookieName>{name}</CookieName>
                                <CookieValue>{value}</CookieValue>
                            </React.Fragment>
                        ))}
                    </CookieValues>
                </div>
                <div>
                    <h3>Server Cookies</h3>
                    {serverError && (
                        <ErrorComp message={serverError.message} />
                    )}
                    <CookieValues>
                        {serverCookies.map(({ name, value }) => (
                            <React.Fragment key={name}>
                                <CookieName>{name}</CookieName>
                                <CookieValue>{value}</CookieValue>
                            </React.Fragment>
                        ))}
                    </CookieValues>
                </div>
            </TwoColumnGrid>

            <h3>S2 User</h3>
            {s2Error && (
                <ErrorComp message={s2Error.message} />
            )}
            {s2User && (
                <pre>{JSON.stringify(s2User, null, 2)}</pre>
            )}
        </React.Fragment>
    );
};

const CookieValues = styled.dl``;

const CookieName = styled.dt`
    font-weight: bold;
`;

const CookieValue = styled.dd`
    word-break: break-all;
`;

const TwoColumnGrid = styled.div`
    display: flex;
    gap: ${({ theme }) => `${theme.spacing.xs}`};
`;
