import axios from 'axios';

export interface UserResponse {
    hasAlertsHistory: boolean,
    hasRecentAuthorCitations: boolean,
    hasRecommendationsHistory: boolean,
    isImposter: boolean,
    user: User,
}

export interface User {
    claimedAuthorId: number | null,
    email: string | null,
    fullName: string | null,
    hash: string,
    hubSpotContactId: number,
    id: number
}

export async function getUser(): Promise<UserResponse> {
    const resp = await axios.get<UserResponse>(
        'https://www.semanticscholar.org/api/1/user',
        { withCredentials: true }
    );
    return resp.data;
}
