import axios from 'axios';

export interface ICookie {
    name: string,
    value: string,
};

export async function getLocalCookies(): Promise<ICookie[]> {
    const cookies: ICookie[] = document.cookie
        .split(/; ?/)
        .map((c) => c.split('='))
        .map((arr) => ({ name: arr.shift() || '', value: arr.join('=') }));
    cookies.sort((a, b) => a.name.localeCompare(b.name));
    return cookies;
}

export async function getServerCookies(): Promise<ICookie[]> {
    const resp = await axios.get<any>('/api/cookies')
    const cookies: ICookie[] = [];
    for (const [name, value] of Object.entries(resp.data.cookies)) {
        if (typeof name !== 'string' || typeof value !== 'string') {
            continue;
        }
        cookies.push({ name, value });
    }
    cookies.sort((a, b) => a.name.localeCompare(b.name));
    return cookies;
}
